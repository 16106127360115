<template>
  <div class="height1">
    <bread></bread>
    <div class="center-content">
      <div class="iq-card-body">
        <div class="table-top">
          <div class="iq-search-bar">
            <el-form :model="searchForm" :inline="true">
              <el-form-item label="药品分类">
                <el-select v-model="searchForm.categoryId" placeholder="请选择" size="small" style="width: 120px" @change="Search">
                  <el-option :label="item.categoryName" :value="item.categoryId" v-for="(item, index) in categoryData" :key="index"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="状态">
                <el-select v-model="searchForm.state" placeholder="请选择" size="small" style="margin-right: 10px; width: 120px" @change="Search">
                  <el-option v-for="item in statusOption" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-date-picker v-model="searchForm.datepicker" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions0" size="small" style="margin-right: 10px; width: 260px" clearable @change="Search"> </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-input v-model="searchForm.keyWord" type="text" size="small" placeholder="药品名称/别名/商品名称/条形码" @change="Search" clearable></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="small" @click="Search()">搜索</el-button>
                <el-button type="primary" size="small" @click="addDrug('add')" plain>新增</el-button>
                <!-- <el-button type="warning" size="mini" class="el-icon-download" @click="downloadTemplate">下载模板</el-button> -->
                <el-button type="warning" size="mini" class="el-icon-download" @click="downloadFile">下载模板</el-button>
              </el-form-item>
              <el-form-item>
                <el-upload class="upload" :show-file-list="false" accept=".xlsx,.xls" :auto-upload="true" :headers="headers" :action="fileUploadUrl" :on-success="uploadSuccess">
                  <el-button size="mini" type="success" class="el-icon-upload2">批量导入</el-button>
                </el-upload>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <el-table :data="tableData" stripe style="width: 100%" :header-cell-style="{ background: '#F5F7FC' }" height="calc(100% - 90px)">
          <el-table-column type="index" label="编号" width="50"></el-table-column>
          <el-table-column prop="mzDrug.drugName" label="药品名称">
            <template slot-scope="scope">
              {{ scope.row.mzDrug.drugName }}
              <span v-if="scope.row.mzDrug.drugType == 'T'">(统)</span>
              <span v-if="scope.row.mzDrug.drugType == 'X'">(选)</span>
              <span v-if="scope.row.mzDrug.drugType == 'J'">(精选)</span>
            </template>
          </el-table-column>
          <el-table-column prop="mzDrug.drugAlias" label="别名" align="center"> </el-table-column>
          <el-table-column prop="productName" label="商品名" align="center"> </el-table-column>
          <el-table-column prop="barCode" label="条形码" align="center"> </el-table-column>
          <el-table-column prop="mzDrug.category.categoryName" label="药品分类" align="center"> </el-table-column>
          <el-table-column prop="specification" label="规格" align="center">
            <template slot-scope="scope">
              <p v-if="scope.row.specification">{{ scope.row.specification }}/{{ scope.row.specificationUnit }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="productFactory" label="生产厂家" show-overflow-tooltip align="center"></el-table-column>
          <el-table-column prop="tradingPrice" label="售价(元)" align="center">
            <template slot-scope="scope"> {{ scope.row.tradingPrice.toFixed(2) }}/{{ scope.row.isSeparate ? scope.row.separateNumberUnit : scope.row.specificationUnit }} </template>
          </el-table-column>
          <el-table-column prop="inventoryNumber" label="总库存" align="center">
            <template slot-scope="scope"> {{ scope.row.inventoryNumber ? scope.row.inventoryNumber : 0 }}（{{ scope.row.isSeparate ? scope.row.separateNumberUnit : scope.row.specificationUnit }}） </template>
          </el-table-column>
          <!-- <el-table-column prop="drugType" label="药品类型" align="center">
            <template slot-scope="scope">
              <p v-if="scope.row.drugType == 0">自有药品</p>
              <p v-if="scope.row.drugType == 1">总部药品</p>
              <p v-if="scope.row.drugType == 2">机构私有药品</p>
            </template>
          </el-table-column> -->
          <el-table-column prop="isEnable" label="是否启用" align="center">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.isEnable" active-color="#13ce66" inactive-color="#ff4949" :active-value="true" :inactive-value="false" @change="changeIsEnable(scope.row)"> </el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="addTime" label="添加时间" align="center" width="150px">
            <template slot-scope="scope">
              {{ scope.row.addTime | timefilters }}
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" align="center" width="150px">
            <template slot-scope="scope">
              <span class="info optionBtn" @click="editBtn(scope.row)">编辑</span>
              <span class="primary optionBtn" @click="prviewBtn(scope.row)">预览</span>
              <span class="danger optionBtn" @click="removeBtn(scope.row)">删除</span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @current-change="changePage" :page-size="pageSize" :current-page="pageIndex" :total="dataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
      </div>
    </div>
    <el-dialog :visible.sync="drugInfoDialog" width="80%">
      <div class="table-content">
        <el-form ref="form" :model="form" label-width="100px">
          <div class="title">基本信息</div>
          <el-row :gutter="20" type="flex" justify="space-between">
            <el-col :span="6">
              <el-form-item label="药品名称:">
                <el-input v-model="form.mzDrug.drugName" placeholder="" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="别名:">
                <el-input v-model="form.mzDrug.drugAlias" placeholder="" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="OTC:">
                <el-radio-group v-model="form.mzDrug.isOTC" disabled>
                  <el-radio :label="true">是</el-radio>
                  <el-radio :label="false">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20" type="flex" justify="space-between">
            <el-col :span="6">
              <el-form-item label="药品分类:">
                <el-select v-model="form.mzDrug.categoryId" placeholder="请选择" style="width: 100%" disabled>
                  <el-option :label="item.categoryName" :value="item.categoryId" v-for="(item, index) in categoryData" :key="index"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="国家标准名称:">
                <el-input v-model="form.mzDrug.standardName" placeholder="" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="是否为基药:">
                <el-radio-group v-model="form.mzDrug.isBasice" disabled>
                  <el-radio :label="true">是</el-radio>
                  <el-radio :label="false">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20" type="flex" justify="space-between">
            <el-col :span="6">
              <el-form-item label="药品毒性:">
                <el-select v-model="form.mzDrug.drugToxic" placeholder="请选择" disabled clearable style="width: 100%">
                  <el-option :label="item" :value="item" v-for="item in drugToxicity"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="国家标准编码:">
                <el-input v-model="form.mzDrug.standardCode" placeholder="" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="推荐用量:">
                <el-input v-model="form.mzDrug.recommendDosage" placeholder="推荐用量" disabled style="width: 80%"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20" type="flex" justify="space-between">
            <el-col :span="6">
              <el-form-item label="商品名:">
                <el-input v-model="form.productName" placeholder="商品名" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="包装规格:">
                <el-row>
                  <el-col :span="15"><el-input v-model="form.specification" placeholder="规格包装描述" disabled></el-input></el-col>
                  <el-col :span="1">
                    <div class="text-c">/</div>
                  </el-col>
                  <el-col :span="8">
                    <el-select v-model="form.specificationUnit" placeholder="包装单位" disabled>
                      <el-option :label="item" :value="item" v-for="item in specificationUnit"></el-option>
                    </el-select>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="自定义分类:">
                <el-input v-model="form.selfCategory" placeholder="自定义分类" disabled style="width: 80%"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20" type="flex" justify="space-between">
            <el-col :span="6">
              <el-form-item label="批准文号:">
                <el-input v-model="form.approvalNumber" placeholder="" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="药品条形码:">
                <el-input v-model="form.barCode" placeholder="" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="生产厂家:">
                <el-input v-model="form.productFactory" placeholder="生产厂家" disabled style="width: 80%"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20" type="flex" justify="start">
            <el-col :span="6">
              <el-form-item label="产地:">
                <el-input v-model="form.productPlace" placeholder="" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6" :offset="2"> </el-col>
            <el-col :span="6" :offset="4"> </el-col>
          </el-row>
          <div class="title">库存销售设置</div>
          <el-row :gutter="20" type="flex" justify="start">
            <el-col :span="6">
              <el-form-item label="是否拆零:">
                <el-radio-group v-model="form.isSeparate" disabled>
                  <el-radio :label="true">是</el-radio>
                  <el-radio :label="false">否</el-radio>
                </el-radio-group>
                <!-- <span style="color: red; margin-left: 5px; font-size: 12px">（保存后不支持修改）</span> -->
              </el-form-item>
            </el-col>
            <el-col :span="6" :offset="2">
              <el-form-item label="拆零单位:">
                <el-row>
                  <el-col :span="16">
                    <el-input-number controls-position="right" v-model="form.separateNumber" :precision="0" :step="1" :min="0" class="w-100" disabled></el-input-number>
                  </el-col>
                  <el-col :span="8">
                    <el-select v-model="form.separateNumberUnit" placeholder="单位" disabled>
                      <el-option :label="item" :value="item" v-for="item in specificationUnit"></el-option>
                    </el-select>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :span="6" :offset="2">
              <el-form-item label="供应商:">
                <el-input v-model="form.supplier" placeholder="" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20" type="flex" justify="start">
            <el-col :span="6">
              <el-row>
                <el-col :span="20">
                  <el-form-item label=" 库存阈值:">
                    <el-input-number controls-position="right" v-model="form.inventoryMix" :precision="0" :step="1" :min="0" class="w-100" disabled></el-input-number>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <span style="height: 40px; line-height: 40px; margin-left: 5px" v-if="form.isSeparate">{{ form.separateNumberUnit }}</span>
                  <span style="height: 40px; line-height: 40px; margin-left: 5px" v-else>{{ form.specificationUnit }}</span>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="6" :offset="2">
              <el-row>
                <el-col :span="20">
                  <el-form-item label=" 售价:" prop="tradingPrice">
                    <el-input-number controls-position="right" v-model="form.tradingPrice" :precision="2" :step="1" :min="0" class="w-100" style="position: relative" disabled></el-input-number>
                    <!-- <span style="height: 40px; line-height: 40px; margin-left: 5px; color: red; position: absolute; left: 0; top: 30px; font-size: 12px" v-if="type == 'edit'">将会修改所有批次的价格</span> -->
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <span style="height: 40px; line-height: 40px; margin-left: 5px" v-if="form.isSeparate">元/{{ form.separateNumberUnit }}</span>
                  <span style="height: 40px; line-height: 40px; margin-left: 5px" v-else>元/{{ form.specificationUnit }}</span>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="6" :offset="2">
              <el-form-item label="货架码">
                <el-input v-model="form.shelfNumber" placeholder="" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="1"></el-col>
          </el-row>
          <div class="title">使用信息</div>
          <el-row :gutter="20" type="flex" justify="start">
            <el-col :span="6">
              <el-form-item label="用法:">
                <el-select v-model="form.usage" placeholder="请选择" class="w-100" disabled>
                  <el-option :label="item" :value="item" v-for="item in usage"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8" :offset="2">
              <el-form-item label="单次剂量:">
                <el-row>
                  <el-col :span="16"><el-input v-model="form.dosage" placeholder="单次剂量" disabled></el-input></el-col>
                  <el-col :span="8">
                    <el-select v-model="form.dosageUnit" placeholder="剂量单位" disabled>
                      <el-option :label="item" :value="item" v-for="item in dosageUnit"></el-option>
                    </el-select>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="使用频次:">
                <el-select v-model="form.frequency" placeholder="请选择" class="w-100" disabled>
                  <el-option :label="item" :value="item" v-for="item in frequency"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="1"></el-col>
          </el-row>
          <el-row :gutter="20" type="flex" justify="start">
            <el-col :span="8">
              <el-form-item label="默认开药量:">
                <el-row>
                  <el-col :span="16"><el-input v-model="form.defaultDosage" placeholder="" disabled></el-input></el-col>
                  <el-col :span="4">
                    <span style="height: 40px; line-height: 40px; margin-left: 5px" v-if="form.isSeparate">{{ form.separateNumberUnit }}</span>
                    <span style="height: 40px; line-height: 40px; margin-left: 5px" v-else>{{ form.specificationUnit }}</span>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="炮制方法:">
                <el-select v-model="form.remark" placeholder="选择炮制方法" class="w-100" disabled>
                  <el-option :label="item" :value="item" v-for="item in remark"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import { Outpatient } from '@/components/DrugDomain/Outpatient.js'
export default {
  computed: {
    headers() {
      return {
        Authorization: 'Bearer' + ' ' + JSON.parse(sessionStorage.getItem(this.ClientId + 'Token')).access_token,
      }
    },
  },
  data() {
    var outpatient = new Outpatient(this.TokenClient, this.Services.Drug)
    return {
      OutpatientDomain: outpatient,
      // headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' },
      fileUploadUrl: `${this.Services.Drug}/Upload`,
      tableData: [],
      pageIndex: 1,
      dataTotal: 1,
      pageSize: 0,
      categoryData: [],
      searchForm: {
        state: 0,
        keyWord: '',
        categoryId: 0,
        datepicker: '',
      },
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
      },
      statusOption: [
        {
          value: 0,
          label: '全部',
        },
        {
          value: 1,
          label: '启用',
        },
        {
          value: 2,
          label: '禁用',
        },
      ],
      title: '余额明细',
      DataCount: 0,
      json_fields: {
        订单编号: {
          filed: 'key',
          callback: (val) => {
            return 'NO：' + val.key
          },
        },
        当前余额: 'name',
      },
      fileList: [],
      id: 0,
      specificationUnit: ['盒', '瓶', '支', '袋', '丸', '枚', '片', '粒', '板', 'g'],
      dosageUnit: ['片', '粒', '支', '袋', '枚', '瓶', '滴', '适量', 'ug', 'mg', 'g', 'ul', 'ml', 'IU'],
      usage: ['口服', '外用', '滴眼', '静脉注射', '静脉滴注', '肌内注射', '皮下注射', '皮内注射', '腔内注射', '椎管注射', '动脉注射', '心内注射', '球内注射', '皮下埋植', '口腔喷雾', '口腔吸入', '口腔黏膜给药', '滴鼻', '鼻腔喷雾', '鼻饲', '鼻腔吸入', '滴耳', '眼科外用', '涂于眼睑内', '含漱', '含服', '舌下含服', '直肠给药', '直肠塞入', '肛门涂抹', '阴道给药', '阴道塞入', '阴道擦洗'],
      frequency: ['1日1次(qd)', '1日2次(bid)', '1日3次(tid)', '隔日1次(qod)', '必要时(prn)', '1日4次(qid)', '1周1次(qwd)', '隔周1次(qow)', '隔天1次(qod)', '每晚1次(qn)', '立即(st)'],
      remark: ['先煎', '后下', '包煎', '另煎', '烊化', '冲服', '捣碎'],
      separateNumberUnit: ['片', '粒', '支', '袋', '丸', '枚', '瓶'],
      drugToxicity: ['普通性药品', '剧毒性药品', '放射性药品', '精神性药品', '麻醉药品', '专项精神', '麻醉药', '无毒', '有毒', '第一类精神药品', '第二类精神药品'],
      drugInfoDialog: false,
      form: {
        mzDrug: {
          drugName: '',
          drugAlias: '',
          isOTC: false,
          categoryId: '',
          standardName: '',
          isBasice: false,
          drugToxic: '',
          standardCode: '',
          materialName: '',
          recommendDosage: '',
          drugTypeList: [],
          hashKey: '',
        },
        id: 0,
        drugId: 0,
        productName: '',
        specification: '',
        specificationUnit: '',
        selfCategory: '',
        approvalNumber: '',
        barCode: '',
        productPlace: '',
        productFactory: '',
        isSeparate: false,
        separateNumber: '',
        separateNumberUnit: '',
        supplier: '',
        inventoryMix: 0,
        tradingPrice: 0,
        shelfNumber: '',
        usage: '',
        dosage: '',
        dosageUnit: '',
        frequency: '',
        defaultDosage: '',
        defaultDosageUnit: '',
        remark: '',
      },
      dialogTableVisible: false,
      errData: [],
      errMsg: '',
    }
  },
  mounted() {
    this.getList()
    this.LoadCategory()
  },
  methods: {
    downloadFile() {
      let path = `${this.Services.Drug}/api/MZDrug/DownExcelTemplateOrg`
      axios({
        method: 'GET',
        headers: { 'Content-Type': 'multipart/form-data', Authorization: 'Bearer' + ' ' + JSON.parse(sessionStorage.getItem(this.ClientId + 'Token')).access_token },
        url: path,
        data: {},
        responseType: 'blob',
      }).then((res) => {
        console.log(res)
        const blobUrl = window.URL.createObjectURL(new Blob([res.data]))
          // 创建一个a标签用于下载
          const downloadLink = document.createElement('a')
          downloadLink.href = blobUrl
          downloadLink.download = '药品模版.xlsx' // 指定下载文件名
          document.body.appendChild(downloadLink)
          downloadLink.click() // 模拟点击下载

          // 清理临时的URL对象和下载链接
          document.body.removeChild(downloadLink)
          window.URL.revokeObjectURL(blobUrl)
      })
        .catch((error) => {
          console.error('下载文件失败:', error)
        })
    },
    downloadTemplate() {
      let a = document.createElement('a')
      a.href = './OrgDrugImp.xlsx'
      a.download = '药品模版.xlsx'
      a.style.display = 'none'
      document.body.appendChild(a)
      a.click()
      a.remove()
    },
    uploadSuccess(res, file) {
      var _this = this
      let filePath = `${res[0].completeFilePath}`.trim()
      this.OutpatientDomain.ImportOrgDrugData(
        filePath,
        function (data) {
          _this.$alert(data.data, '提示', {
            confirmButtonText: '确定',
            callback: (action) => {
              _this.pageIndex = 1
              _this.getList()
            },
          })
        },
        function (err) {
          let jsonObj = JSON.parse(err.msg)
          // _this.$message({
          //   message: jsonObj.msg,
          //   type: 'error',
          // })
          _this.$alert(jsonObj.msg, '提示', {
            confirmButtonText: '确定',
            callback: (action) => {},
          })
        }
      )
    },
    changeIsEnable(item) {
      var _this = this
      _this.OutpatientDomain.IsEnableDrug(
        item.id,
        (data) => {
          _this.$message({
            type: 'success',
            message: '操作成功!',
          })
        },
        (err) => {
          console.log(err)
        }
      )
    },
    removeBtn(item) {
      var _this = this
      this.$confirm('确定删除该药品, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          _this.OutpatientDomain.RemoveMZDrugOrg(
            item.id,
            function (data) {
              _this.getList()
              _this.$message({
                type: 'success',
                message: '删除成功!',
              })
            },
            function (err) {
              console.log(err)
            }
          )
        })
        .catch(() => {})
    },
    getDetail() {
      var _this = this
      _this.OutpatientDomain.MZDrugOrgDetail(
        this.id,
        function (data) {
          _this.form = data.data
          _this.drugInfoDialog = true
        },
        function (err) {
          console.log(err)
        }
      )
    },
    editBtn(item) {
      this.id = item.id
      this.addDrug('edit')
    },
    prviewBtn(item) {
      this.id = item.id
      this.getDetail()
    },
    addDrug(str) {
      this.$router.push({
        path: './AddDrug',
        query: {
          type: str,
          id: this.id,
        },
      })
    },
    LoadCategory() {
      var _this = this
      _this.OutpatientDomain.GetOutpatientCategories(
        function (data) {
          _this.categoryData = data.data.categories
          _this.categoryData.unshift({ categoryId: 0, categoryName: '全部' })
        },
        function (err) {
          console.log(err)
        }
      )
    },
    changePage(pageIndex) {
      this.pageIndex = pageIndex
      this.getList()
    },
    getList() {
      var _this = this
      _this.tableData = []
      var item = _this.searchForm
      if (_this.searchForm.datepicker) {
        item.startTime = _this.searchForm.datepicker[0] + ' 00:00:00'
        item.endTime = _this.searchForm.datepicker[1] + ' 23:59:59'
      } else {
        item.startTime = ''
        item.endTime = ''
      }
      _this.OutpatientDomain.DrugOrgList(
        item.keyWord,
        item.categoryId,
        item.state,
        item.startTime,
        item.endTime,
        this.pageIndex,
        function (data) {
          _this.tableData = data.data.results
          _this.pageIndex = data.data.pageIndex
          _this.pageSize = data.data.pageSize
          _this.dataTotal = data.data.dataTotal
        },
        function (err) {
          console.log(err)
        }
      )
    },
    Search(event) {
      this.pageIndex = 1
      this.getList()
    },
    // 导入数据
    handleExcel(file, fileList) {
      console.log(file.raw)
      let formData = new FormData() //声明一个FormDate对象
      formData.append('formFile', file.raw) //把文件信息放入对象中
    },
    async fetchData() {
      var _this = this
      return await _this.getAllData()
    },
    getAllData() {
      var _this = this
      return new Promise((resolve, reject) => {
        // var item = _this.searchForm;
        // if (_this.searchForm.datepicker) {
        //   item.startTime = _this.searchForm.datepicker[0] + " 00:00:00";
        //   item.endTime = _this.searchForm.datepicker[1] + " 23:59:59";
        // } else {
        //   item.startTime = "";
        //   item.endTime = "";
        // }
        // _this.BalanceDomain.BalanceDetail(
        //   item.startTime,
        //   item.endTime,
        //   item.keyWord,
        //   item.state,
        //   1,
        //   _this.DataCount,
        //   function (data) {
        //     resolve(data.data.results);
        //   },
        //   function (err) {
        resolve('')
        //     console.log(err);
        //   }
        // );
      })
    },
  },
}
</script>

<style scoped lang="scss">
.dialog-form {
  width: 85%;
}
.table-content {
  padding-left: 20px;

  .title {
    background: #f4f5f7;
    height: 40px;
    line-height: 40px;
    padding: 0 15px;
    margin-bottom: 20px;
    margin-right: 20px;
    border-radius: 5px;
  }

  ::v-deep .el-table__row > td {
    /* 去除表格线 */
    border: none !important;
  }

  ::v-deep .el-table th.is-leaf {
    /* 去除上边框 */
    border: none !important;
  }

  ::v-deep .el-table::before {
    /* 去除下边框 */
    height: 0 !important;
  }

  ::v-deep .el-table .el-table__cell {
    padding: 0 !important;
  }

  .my-table {
    border: 1px solid #ebeef5;
    padding: 10px 5px;
  }
}
</style>
